export const hcpHome: any = {
  hcpHomeMainMenu: "Main Navigational Menu HCP GCSO - Retina Spain",
  hcpHomeCarousel: "Homepage Carousal HCP - Retina Spain",
  hcpFooter: "Footer HCP - Retina Spain",
  hcpExitPopup: "Exit Popup - HCP - Retina Spain",
  hcpHCPSwitcher: "HCP Switcher Modal Pop Up - Retina Spain",
  hcpTopNav: "Top Navigational Menu HCP - Retina Spain",
  hcpFooterCallout: "Homepage Footer Callout HTML - HCP - Retina Spain",
  hcpSiteLogo: "Site logos - Retina Spain",
  hcpAmericalData: "American Academy of Ophthalmology HTML - Retina Spain",
  hcpAdvancingScience: "Advancing Science HTML - Retina Spain",
  hcpOverlap: "Overlapping presentations of IRD HTML - Retina Spain",
  hcpHomeFootRef: "HCP Home Reference - Retina Spain",
  hcpHomeVideoCarousel: "DNA Video and Carousel - Retina Spain",
  backToTopGTM: "back to top - homepage - hcp",
  carousalAttrs: {
    playBtnGTM: {
      'className': 'gtm-link-internal',
      'data-gtm-event-label': 'hero image - play',
      'aria-label': 'Play diapositivas'
    },
    pauseBtnGTM: {
      'className': 'gtm-link-internal',
      'data-gtm-event-label': 'hero image - pause',
      'aria-label': 'Pausar diapositivas'
    },
    btnTexts: {
      'play': 'REPRODUCIR',
      'pause': 'PAUSA'
    },
    playBtnAriaDescription: ' ',
    pauseBtnAriaDescription: ' '
  },
  videoCarousalAttrs: {
    playBtnGTM: {
      'className': 'gtm-link-internal',
      'data-gtm-event-label': 'new gene variants - play',
      'aria-label': 'Play diapositivas'
    },
    pauseBtnGTM: {
      'className': 'gtm-link-internal',
      'data-gtm-event-label': 'new gene variants - pause',
      'aria-label': 'Pausar diapositivas'
    },
    btnTexts: {
      'play': 'REPRODUCIR',
      'pause': 'PAUSA'
    }
  },
  footerClassName: "home-footer"
}
